@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 240 5% 96%;
    --foreground: 240 4% 16%;

    --skeleton: 240 5% 84%;

    --card: 0 0% 100%;
    --card-secondary: 0 0% 98%;
    --card-foreground: var(--foreground);

    --image: 240 5% 26%;

    --popover: var(--card);
    --popover-foreground: var(--card-foreground);

    --tooltip: 0 0% 0%;
    --tooltip-foreground: 0 0% 100%;

    --primary: 239 78% 65%;
    --primary-hover: 243 75% 59%;
    --primary-active: 245 58% 51%;
    --primary-foreground: 0 0% 100%;
    --primary-subtle: 230 94% 82%;
    --primary-subtle-foreground: var(--primary);

    --secondary: 0, 0%, 0%, 0.06;
    --secondary-hover: 0, 0%, 0%, 0.08;
    --secondary-active: 0, 0%, 0%, 0.16;
    --secondary-foreground: 220.9 39.3% 11%;
    --secondary-subtle: 240 5% 65%;
    --secondary-subtle-foreground: var(--foreground);

    --info: 217 91 60%;
    --info-hover: 221 83% 53%;
    --info-active: 224 76% 48%;
    --info-foreground: 0 0% 100%;
    --info-subtle: 212 96% 78%;
    --info-subtle-foreground: var(--info);

    --destructive: 0 84% 60%;
    --destructive-hover: 0 72% 51%;
    --destructive-active: 0 74% 42%;
    --destructive-foreground: 210 20% 98%;
    --destructive-subtle: 0 94% 82%;
    --destructive-subtle-foreground: var(--destructive);

    --success: 142 71% 45%;
    --success-hover: 142 76% 36%;
    --success-active: 142 72% 29%;
    --success-foreground: 0 0% 100%;
    --success-subtle: 141 79% 85%;
    /* --success-subtle-foreground: var(--success); */
    --success-subtle-foreground: 142 76% 36%; /* green 600 */

    --warning: 24 75% 50%;
    --warning-hover: 20 71% 44%;
    --warning-active: 16 65% 37%;
    --warning-foreground: 0 0% 100%;
    --warning-subtle: 33 90% 65%;
    --warning-subtle-foreground: var(--warning);

    /* these are stored as hex so it's consistent with custom values on profiles with backgroundImageUrl. We plan to migrate all colors to hex values */
    --banner: #27272a;
    --banner-dark: #1e1e1f;
    --banner-foreground: #f9fafb;
    --banner-opacity: 1;

    --muted: 0 0% 100%;
    --muted-foreground: 240 5% 46%;

    --accent: 240 5% 96%;
    --accent-foreground: 240 4% 16%;

    --anchor-foreground: 221 83% 53%;

    --border: 240 5.9% 90%;
    --border-muted: 240 3% 93%;

    --input-border: 240, 6%, 90%, 100%;
    --input-border-accent: 240, 5%, 84%, 100%;
    --input-border-invalid: 0 84% 60%;
    --input-background: 0, 0%, 100%, 100%;

    --ring: 240, 0%, 45%, 25%;

    --alert-success: 137 64% 98%;
    --alert-success-icon: 142 71% 45%;
    --alert-info: 218 80% 98%;
    --alert-info-icon: 217 91% 60%;
    --alert-warning: 40 79% 96%;
    --alert-warning-icon: 27 96% 61%;
    --alert-error: 0 78% 98%;
    --alert-error-icon: 0 84% 60%;

    --blackAlpha-soft: rgba(0, 0, 0, 0.06);
    --blackAlpha-medium: rgba(0, 0, 0, 0.16);
    --blackAlpha-hard: rgba(0, 0, 0, 0.8);


    /* not sure about this approach, we should think about this one too */
    --gray: 240 3% 46%; /* gray.500 from Chakra */

    --discord: 233 78% 63%;
    --discord-hover: 243 75% 59%;
    --discord-active: 245 58% 51%;
    --telegram: 200 73% 50%;
    --telegram-hover: 200 98% 39%;
    --telegram-active: 201 96% 32%;
    --email: 217 91% 60%;
    --email-hover: 221 83% 53%;
    --email-active: 224 76% 48%;
    --google: 217 91% 60%;
    --google-hover: 221 83% 53%;
    --google-active: 224 76% 48%;
    --twitter: 240 4% 14%;
    --twitter-hover: 240 4% 10%;
    --twitter-active: 240 4% 6%;
    --github: 240 4% 14%;
    --github-hover: 240 4% 10%;
    --github-active: 240 4% 6%;
    --worldid: 240 4% 14%;
    --worldid-hover: 240 4% 10%;
    --worldid-active: 240 4% 6%;

    --farcaster: 261 55% 61%;
    --farcaster-hover: 261 55% 49%;
    --farcaster-active: 261 54% 37%;

    --uniswap: 331 100% 45%;
    --uniswap-hover: 332 100% 35%;
    --uniswap-active: 331 100% 25%;

    --web3inbox: 179 99% 32%;
    --web3inbox-hover: 179 99% 29%;
    --web3inbox-active: 179 98% 25%;

    /**
     * Missing reward colors to work with the current Chakra driven solution.
     * Should find a better solution when we ditch Chakra and won't need to be backward compatible
     *
     * Examples of usage -> new: RewardBadge, old: RewardTag
     */
    --gather_town: 232, 66%, 55%;
    --cyan: 190, 100%, 42%;
    --blue: 217, 91%, 60%;
    --gold: 40 67% 51%;
    --gray: 240, 4%, 46%;

    --sm: 640px;
    --md: 768px;
    --lg: 1024px;
    --xl: 1280px;
  }

  :root[data-theme="dark"], [data-theme="dark"] {
    --background: 240 3.7% 15.88%;
    --foreground: 210 20% 98%;

    --skeleton: 240 5% 40%;

    --card: 240 5.26% 26.08%;
    --card-secondary: 240 5% 22%;
    --card-foreground: var(--foreground);

    --image: 240 5% 34%;

    --popover: var(--card);
    --popover-foreground: var(--card-foreground);

    --tooltip: 240 5% 84%;
    --tooltip-foreground: 240 4% 16%;

    --primary: 239 78% 65%;
    --primary-hover: 234 89% 74%;
    --primary-active: 230 94% 82%;
    --primary-foreground: 0 0% 100%;
    --primary-subtle: 230 94% 82%;
    --primary-subtle-foreground: var(--primary-subtle);

    --secondary: 0, 0%, 100%, 0.08;
    --secondary-hover: 0, 0%, 100%, 0.16;
    --secondary-active: 0, 0%, 100%, 0.24;
    --secondary-foreground: 0 0% 100%;
    --secondary-subtle: 240 6% 90%;
    --secondary-subtle-foreground: var(--foreground);

    --info: 217 91% 60%;
    --info-hover: 213 94% 68%;
    --info-active: 212 96% 78%;
    --info-foreground: 0 0% 100%;
    --info-subtle: 212 96% 78%;
    --info-subtle-foreground: var(--info-subtle);

    --destructive: 0 84% 60%;
    --destructive-hover: 0 91% 71%;
    --destructive-active: 0 94% 82%;
    --destructive-foreground: 210 20% 98%;
    --destructive-subtle: 0 94% 82%;
    --destructive-subtle-foreground: var(--destructive-subtle);

    --success: 142 71% 45%;
    --success-hover: 142 69% 58%;
    --success-active: 142 77% 73%;
    --success-foreground: 0 0% 100%;
    --success-subtle: 141 79% 85%;
    --success-subtle-foreground: var(--success-subtle);

    --warning: 24 75% 50%;
    --warning-hover: 27 84% 57%;
    --warning-active: 33 90% 65%;
    --warning-foreground: 0 0% 100%;
    --warning-subtle: 33 90% 65%;
    --warning-subtle-foreground: var(--warning-subtle);

    --banner: #36363a;

    --muted: 240 3% 13%;
    --muted-foreground: 240 5% 60%;

    --accent: 240 2% 35%;
    --accent-foreground: 0 0% 92%;

    --anchor-foreground: 213 94% 68%;

    --border: 240 3% 38%;
    --border-muted: 240 3% 28%;

    --input-border: 0, 0%, 100%, 16%;
    --input-border-accent: 0, 0%, 100%, 24%;
    --input-border-invalid: 0 94% 82%;
    --input-background: 0, 0%, 0%, 16%;

    --ring: 240 0% 45%;

    --alert-success: 180 4% 35%;
    --alert-success-icon: 141 79% 85%;
    --alert-info: 226 7% 36%;
    --alert-info-icon: 213 97% 87%;
    --alert-warning: 30 6% 34%;
    --alert-warning-icon: 32 98% 83%;
    --alert-error: 317 4% 35%;
    --alert-error-icon: 0 94% 82%;

    --discord-hover: 234 89% 74%;
    --discord-active: 230 94% 82%;
    --telegram-hover: 198 93% 60%;
    --telegram-active: 199 95% 74%;
    --email-hover: 213 94% 68%;
    --email-active: 212 96% 78%;
    --google-hover: 213 94% 68%;
    --google-active: 212 96% 78%;

    --farcaster-hover: 261 54% 69%;
    --farcaster-active: 262 54% 77%;

    --uniswap-hover: 331 100% 55%;
    --uniswap-active: 331 100% 65%;

    --web3inbox-hover: 179 99% 38%;
    --web3inbox-active: 179 99% 44%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply min-h-screen bg-background font-sans text-foreground antialiased;
  }
}

@layer utilities {
  .scroll-shadow,
  .scroll-shadow [data-radix-scroll-area-viewport] {
    --scroll-shadow-bg: hsl(var(--card-secondary));
    --scroll-shadow-from: rgba(0, 0, 0, 0.1);
    --scroll-shadow-to: rgba(0, 0, 0, 0);
    
    background:
    /* Shadow Cover TOP */
    linear-gradient(
      var(--scroll-shadow-bg) 30%,
      rgba(255, 255, 255, 0)
    ) center top,
    
    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(255, 255, 255, 0), 
      var(--scroll-shadow-bg) 70%
    ) center bottom,
    
    /* Shadow TOP */
    linear-gradient(
      to bottom,
      var(--scroll-shadow-from),
      var(--scroll-shadow-to)
    ) center top,
    
    /* Shadow BOTTOM */
    linear-gradient(
      to top,
      var(--scroll-shadow-from),
      var(--scroll-shadow-to)
    ) center bottom;
  
  background-repeat: no-repeat;
  background-size: 100% 2.5rem, 100% 2.5rem, 100% 1rem, 100% 1rem;
  background-attachment: local, local, scroll, scroll;
  }

  [data-theme="dark"] .scroll-shadow {
    --scroll-shadow-from: rgba(0, 0, 0, 0.25);
    --scroll-shadow-to: rgba(0, 0, 0, 0);
  }

  .custom-scrollbar::-webkit-scrollbar,
  .custom-menu-list > div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-track,
  .custom-menu-list > div::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb,
  .custom-menu-list > div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.4);
  }

  .invisible-scrollbar::-webkit-scrollbar,
  .invisible-menu-list > div::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .invisible-scrollbar::-webkit-scrollbar-track,
  .invisible-menu-list > div::-webkit-scrollbar-track {
    width: 0;
    height: 0;
  }

  .invisible-scrollbar::-webkit-scrollbar-thumb,
  .invisible-menu-list > div::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
  }

  .prose-counters {
    counter-reset: main-counter;
  }

  .prose-counters section:not(:first-child) {
    counter-increment: main-counter;
  }

  .prose-counters ol li::marker {
    content: counter(main-counter) ". ";
  }

  .prose-counters ol li ol {
    counter-reset: sub-counter;
  }

  .prose-counters ol li ol li {
    counter-increment: sub-counter;
  }

  .prose-counters ol li ol li::marker {
    content: counter(main-counter) "." counter(sub-counter) " ";
  }

  .prose table {
    @apply border
  }

  .prose table thead,
  .prose table tr {
    @apply border-border
  }

  .prose table th,
  .prose table td {
    @apply p-4
  }
}

/*
  Hides the default reCaptcha badge. We do indicate reCaptcha usage in the WalletSelectorModal
  https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge#answer-44543771
*/
.grecaptcha-badge {
  visibility: hidden;
}

/*
  Make sure the captcha works inside modals...
*/
iframe[src*='/recaptcha'] {
  pointer-events: all;
}

div[class^="intercom-with-namespace-"],
div[aria-label="Load Chat"],
.intercom-launcher {
  @apply !bottom-3
}
